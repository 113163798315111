import service from "@/common/request.js";
/**
 * get_company_list
 * @param {search_type} number 0 精准 1模糊
 * @param {company_name} string 企业名字
 *
 * @return {data: [{ value: "数行" }, { value: "数行2" }] }
 */
export function get_company_list(param) {
  return service.post("/company/reality-check/", param);
  //   return service.post("get_company_list", param);
}

/**
 * get_company_list
  查询是否重复

   
 */

export function createcompany(param) {
  return service.post("/company/create-real-comp/", param);
  //   return service.post("get_company_list", param);
}

export function compute(param) {
  return service.post("/upload/multiple-fs/calc/", param);

  //
}

export function ocrUp(param) {
  return service.post("/upload/ocr/third-part/", param);
  //   return service.post("get_company_list", param);
}
