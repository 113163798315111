<template>
  <div>
    <div class="topnav fixed-top page_head">
      <vhead></vhead>
    </div>
    <div id="reportManagement">
      <div class="ManageTop">
        <div class="title">
          <img
            src="@/assets/img/head/icon.png"
            style="margin-bottom: 5px; margin-right: 5px"
          />
          基本信息
        </div>
        <div class="pl-4 pt-3">
          <el-row>
            <el-col :span="8" class="row"
              ><span class="TitleText">公司名称</span>
              <div class="grid-content bg-purple ml-2">{{ company_name }}</div>
            </el-col>
            <!-- <el-col :span="8" class="row"><span class="TitleText">客户号</span>
                            <div class="grid-content bg-purple-light ml-2">{{ company_code }}</div>
                        </el-col> -->
          </el-row>
        </div>
      </div>
      <div class="ManageBottom mt-3">
        <div class="manage row">
          <div class="title">
            <img
              src="@/assets/img/head/icon.png"
              style="margin-bottom: 5px; margin-right: 5px"
            />
            报表管理
          </div>
          <div>
            <!-- <el-button type="primary" :disabled="role_kind == '2'" size="small"
                            @click="onUpload = true">上传报表</el-button> -->
            <el-dropdown trigger="click" @command="handleCommand" class="mr-4">
              <el-button type="primary" size="small" style="width: 110px">
                上传报表
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="UP">固定模版上传</el-dropdown-item>
                <el-dropdown-item command="OC">报表OCR识别</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="primary"
              style="width: 110px"
              :disabled="role_kind == '2'"
              size="small"
              @click="handMadeFrom('0', '0', '0')"
              >手工录入
            </el-button>
          </div>
        </div>
        <div v-show="!showFlag">
          <el-table
            stripe
            ref="filterTable"
            @sort-change="sortChange"
            @filter-change="filterChange"
            :data="tableData"
            style="width: 100%; color: #333; font-size: 14px"
            :maxHeight="tableContentHeight"
            :minHeight="200"
            :header-cell-style="{
              background: '#E9F3FF',
              color: '#333',
              height: '48px',
            }"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column prop="fs_no" label="报表编号">
              <template slot-scope="{ row }">
                <span>{{ row.fs_no }} </span>
                <el-tag
                  v-show="row.is_ocr !== 0"
                  type=""
                  effect="plain"
                  size="small"
                >
                  OCR识别
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="creation_time"
              label="生成日期"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                <span v-if="row.report_time == '00'">{{
                  row.creation_time.substring(0, 4)
                }}</span>
                <span v-else>{{ row.creation_time.substring(0, 10) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="report_time" label="报表类型">
              <template slot-scope="{ row }">
                <span v-if="row.report_time == '00'">年报</span>
                <span v-else>月报</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="report_type"
              label="报表种类"
              column-key="report_type"
              :filters="[
                { text: '合并报表', value: '00' },
                { text: '本部报表', value: '01' },
              ]"
            >
              <template slot-scope="{ row }">
                <span v-if="row.report_type == '00'">合并报表</span>
                <span v-if="row.report_type == '01'">本部报表</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="creater_name" label="登记人">
                        </el-table-column> -->
            <el-table-column
              prop="update_time"
              label="登记日期"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                {{ row.update_time.substring(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" style="font-size: 16px" width="300px">
              <template slot-scope="scope">
                <a
                  type="text"
                  style="height: 15px"
                  class="see_report pr-1 pl-1"
                  @click="onCompanyLook(scope.row)"
                  >点击分析</a
                >
                <span class="sc">| </span>
                <a
                  type="text"
                  style="height: 15px"
                  class="see_report pr-1 pl-1"
                  @click="handMadeFrom('1', scope.row, '0')"
                  >查看报表</a
                >
                <span class="sc">| </span>
                <a
                  type="text"
                  style="height: 15px"
                  class="see_report pr-1 pl-1"
                  :disabled="role_kind == '2'"
                  :style="role_kind == '2' ? color : ''"
                  @click="handMadeFrom('2', scope.row, '0')"
                  >修改报表</a
                >
                <span class="sc">| </span>
                <a
                  type="text"
                  style="height: 15px"
                  class="see_report pr-1 pl-1"
                  @click="handMadeFrom('3', scope.row, '0')"
                  >修改记录</a
                >
                <!-- <a type="text" style="height:15px" class="see_report pr-1 pl-1" :disabled="role_kind == '2'"
                                :style="role_kind == '2' ? color : ''" @click="onDeleteclick(scope.row)">删除</a> -->
              </template>
            </el-table-column>
          </el-table>
          <div class="text-right mt-3">
            <el-pagination
              hide-on-single-page="true"
              :current-page="Page"
              :total="totals"
              :page-sizes="[10, 20, 30]"
              :page-size="Size"
              background
              layout="total,prev, pager, next, sizes,  jumper"
              @size-change="SizeChange"
              @current-change="CurrentChange"
            />
          </div>
        </div>
        <div v-show="showFlag" class="text-center" style="width: 100%">
          <img
            src="@/assets/img/NewProject/nodata.svg"
            style="width: 20%"
            alt=""
          />
          <p>
            暂未导入数据！
            <span v-show="role_kind !== '2'">
              开始<a style="color: #1989fa" @click="onUpload = true">
                固定模板上传</a
              >或
              <a style="color: #1989fa" @click="handleCommand('OC')">
                报表OCR识别
              </a>
              或
              <a style="color: #1989fa" @click="handMadeFrom(0, 0, 0)">
                手工录入
              </a>
            </span>
          </p>
        </div>
        <!-- 上传弹框 -->
        <el-dialog
          title="上传报表"
          :visible.sync="onUpload"
          width="500px"
          :close-on-click-modal="false"
          :before-close="handleDialogClose"
        >
          <div class="dialogBox">
            <div class="mt-3">
              <span class="mr-3" style="color: #333; font-size: 14px"
                >企业名称</span
              >
              <span>{{ company_name }}</span>
            </div>
            <div class="mt-3">
              <span class="mr-3" style="color: #333; font-size: 14px"
                >固定模板</span
              >
              <span>
                <el-dropdown
                  trigger="click"
                  placement="bottom-start"
                  @command="download"
                >
                  <el-button
                    :loading="load"
                    size="medium"
                    class="el-icon-download"
                    style="color: #1989fa; border: 1px solid #1989fa"
                  >
                    下载模板</el-button
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="一般企业-单体模板.xlsx"
                      >一般企业-单体模板.xlsx</el-dropdown-item
                    >
                    <el-dropdown-item command="一般企业-合并模板.xlsx"
                      >一般企业-合并模板.xlsx</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="一般企业-三年一期模板-单体模板.xlsx"
                      >一般企业-三年一期模板-单体模板.xlsx</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="一般企业-三年一期模板-合并模板.xlsx"
                      >一般企业-三年一期模板-合并模板.xlsx</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
            <div class="row mt-3" style="width: 100%">
              <div class="mr-3" style="color: #333; font-size: 14px">
                上传文件
              </div>
              <el-upload
                class="upload-demo"
                drag
                action
                name="file"
                accept=".xlsx"
                :auto-upload="false"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="filelist"
                :on-change="handleChansge"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  <p>将文件拖到此处，或<em>点击上传</em></p>
                  <p>文件只能上传固定模板格式,否则无法分析</p>
                </div>
              </el-upload>
            </div>
            <div class="text-center mt-4">
              <el-button
                @click="handleDialogClose"
                size="small"
                style="
                  color: #1989fa;
                  border: 1px solid #1989fa;
                  width: 120px;
                  font-size: 14px;
                "
                >取 消</el-button
              >
              <el-button
                style="width: 120px; font-size: 14px"
                class="ml-4"
                type="primary"
                @click="clickconfirm"
                size="small"
                :disabled="btn.disable"
                >{{ btn.message }}</el-button
              >
            </div>
          </div>
        </el-dialog>

        <el-dialog
          title="录入信息"
          :visible.sync="luFrom"
          width="35%"
          :before-close="handleClose"
        >
          <div style="padding-left: 20px; padding-right: 20px">
            <div class="row align-items-center">
              <div class="mr-4" style="font-size: 16px; color: #333">
                客户名称
              </div>
              <div style="font-size: 16px; color: #333">{{ company_name }}</div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="mr-4" style="font-size: 16px; color: #333">
                报表类型
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-select v-model="BType" size="medium" style="width: 100%">
                  <el-option
                    v-for="item in pageTypeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="mr-4" style="font-size: 16px; color: #333">
                报表日期
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-date-picker
                  :picker-options="setDateRange"
                  style="width: 100%"
                  :value-format="BType == '00' ? 'yyyy' : 'yyyy-MM'"
                  size="medium"
                  v-model="dateTime"
                  :type="BType == '00' ? 'year' : 'month'"
                  placeholder="选择报表日期"
                  :popper-append-to-body="false"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="mr-4" style="font-size: 16px; color: #333">
                报表种类
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-select v-model="codetype" size="medium" style="width: 100%">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              style="width: 180px; height: 32px; line-height: 0px"
              @click="tick"
              >下一步</el-button
            >
          </span>
        </el-dialog>

        <el-dialog
          title="上传报表"
          :visible.sync="upDataFrom"
          width="30%"
          :before-close="handleClose"
        >
          <div style="padding-left: 20px; padding-right: 20px">
            <div class="row align-items-center">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                客户名称
              </div>
              <div style="font-size: 16px; color: #333">{{ company_name }}</div>
            </div>
            <div class="row align-items-center mt-3">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                报表类型
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-select v-model="BType" size="medium" style="width: 100%">
                  <el-option
                    v-for="item in pageTypeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                报表日期<i style="color: red">*</i>
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-date-picker
                  :picker-options="setDateRange"
                  style="width: 100%"
                  :value-format="BType == '00' ? 'yyyy' : 'yyyy-MM'"
                  size="medium"
                  v-model="dateTime"
                  :type="BType == '00' ? 'year' : 'month'"
                  placeholder="选择报表日期"
                  :popper-append-to-body="false"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                报表种类
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-select v-model="codetype" size="medium" style="width: 100%">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                资产负债表<i style="color: red">*</i>
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-upload
                  class="upload-demo zc"
                  action
                  name="file"
                  :limit="1"
                  :auto-upload="false"
                  :on-exceed="fbhandleExceed"
                  :on-change="fbUploada"
                  :file-list="fbFileList"
                  :on-remove="fbRemove"
                >
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-upload2"
                    style="border-color: #14c984; background: #14c984"
                    v-show="fbFileList.length == 0"
                    >点击上传</el-button
                  >
                </el-upload>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                利润表<i style="color: red">*</i>
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-upload
                  class="upload-demo zc"
                  action
                  name="file"
                  :limit="1"
                  :auto-upload="false"
                  :on-exceed="fphandleExceed"
                  :on-change="fpUploada"
                  :file-list="fpFileList"
                  :on-remove="fpRemove"
                >
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-upload2"
                    style="border-color: #14c984; background: #14c984"
                    v-show="fpFileList.length == 0"
                    >点击上传</el-button
                  >
                </el-upload>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div
                class="mr-4 text-right"
                style="width: 90px; font-size: 16px; color: #333"
              >
                现金流量表
              </div>
              <div style="font-size: 16px; color: #333; width: 70%">
                <el-upload
                  class="upload-demo zc"
                  action
                  name="file"
                  :limit="1"
                  :auto-upload="false"
                  :on-exceed="fchandleExceed"
                  :on-change="fcUploada"
                  :file-list="fcFileList"
                  :on-remove="fcRemove"
                >
                  <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-upload2"
                    style="border-color: #14c984; background: #14c984"
                    v-show="fcFileList.length == 0"
                    >点击上传</el-button
                  >
                </el-upload>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              style="
                width: 180px;
                height: 39px;
                border-color: #3a85fd;
                color: #3a85fd;
                line-height: 0px;
              "
              @click="handleCloseOcr"
              >取 消</el-button
            >
            <el-button
              type="primary"
              style="width: 180px; height: 39px; line-height: 0px"
              @click="ocrConfirm"
              :loading="load"
              >{{ loadText }}</el-button
            >
          </span>
        </el-dialog>
      </div>

      <!-- 补充数据 -->
      <!-- <div class="ManageBottom mt-3">
                <pragmatic></pragmatic>
                <div class="manage row">
                    <div class="title">
                        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px;margin-right: 5px" />
                        补充附注表管理
                    </div>
                    <div>
                        <el-button type="primary" size="small" :disabled="role_kind == '2'"
                            @click="pragmaticUploadForm('0', '0', '1')">
                            手工录入</el-button>
                    </div>
                </div>
                <div class="mt-3">
                    <el-button-group>
                        <el-button plain class="btn showBtn" @click="kindClick('s1')"
                            :class="showClick == 's1' && 'active'">授信辅助表
                        </el-button>
                        <el-button plain class="btn" @click="kindClick('s2')" :class="showClick == 's2' && 'active'">
                            补充数据表</el-button>
                        <el-button plain title="应收账款及应付账款账龄分析表" class="btn" @click="kindClick('s3')"
                            :class="showClick == 's3' && 'active'">应收账款及应付账款账龄分析表</el-button>
                        <el-button plain class="btn" @click="kindClick('s4')" :class="showClick == 's4' && 'active'">
                            基础数据表</el-button>
                        <el-button plain class="btn" @click="kindClick('s5')" :class="showClick == 's5' && 'active'">
                            企业指标表</el-button>
                    </el-button-group>
                </div>
                <div class="mt-3" v-show="!Flag">
                    <el-table stripe ref="table_cen" @sort-change="sort_Change" @filter-change="filterFie"
                        :data="pragmaticData" style="width: 100%;color:#333;font-size:14px"
                        :default-sort="{ prop: 'date', order: 'descending' }" :maxHeight="tableContentHeight"
                        :minHeight="200" :header-cell-style="{ background: '#E9F3FF', color: '#333', height: '48px' }">
                        <el-table-column prop="fsn_no" label="报表编号">
                        </el-table-column>
                        <el-table-column prop="creation_time" label="生成日期" sortable="custom">
                            <template slot-scope="{ row }">
                                <span v-if="row.report_time == '00'">{{ row.creation_time.substring(0, 4) }}</span>
                                <span v-else>{{ row.creation_time.substring(0, 10) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="report_time" label="报表类型">
                            <template slot-scope="{ row }">
                                <span v-if="row.report_time == '00'">年报</span>
                                <span v-else>月报</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="report_type" label="报表种类"
                            :filters="[{ text: '合并报表', value: '00' }, { text: '本部报表', value: '01' }]"
                            column-key="report_type">
                            <template slot-scope="{ row }">
                                <span v-if="row.report_type == '00'">合并报表</span>
                                <span v-if="row.report_type == '01'">本部报表</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="creater_name" label="登记人">
                        </el-table-column>
                        <el-table-column prop="update_time" label="登记日期" sortable="custom">
                            <template slot-scope="{ row }">
                                {{ row.update_time.substring(0, 10) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" style="font-size:16px" width="240px">
                            <template slot-scope="scope">
                                <a type="text" style="height:15px" class="see_report pr-1 pl-1"
                                    @click="pragmaticUploadForm('1', scope.row, '1')">查看报表</a>
                                <span class="sc">| </span>
                                <a type="text" style="height:15px" class="see_report pr-1 pl-1" :disabled="role_kind == '2'"
                                    :style="role_kind == '2' ? color : ''"
                                    @click="pragmaticUploadForm('2', scope.row, '1')">修改报表</a>
                                <span class="sc">| </span>
                                <a type="text" style="height:15px" slot="reference" class="see_report pr-1 pl-1"
                                    @click="pragmaticUploadForm('3', scope.row, '1')">修改记录</a>
                                <a type="text" style="height:15px" slot="reference" :disabled="role_kind == '2'"
                                :style="role_kind == '2' ? color : ''" class="see_report pr-1 pl-1" @click="onDelete(scope.row)">删除</a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align:right;margin-top:20px;margin-bottom:15px">
                        <el-pagination hide-on-single-page="true" :current-page="currentPage" :total="total"
                            :page-sizes="[10, 20, 30]" :page-size="pageSize" background
                            layout="total,prev, pager, next, sizes,  jumper" @size-change="handleSizeChange"
                            @current-change="handleCurrentChange" />
                    </div>
                </div>
                <div v-show="Flag" class="text-center mt-3" style="width:100%">
                    <img src="@/assets/img/NewProject/nodata.svg" style="width:20%" alt="">
                    <p>暂未导入数据！
                        <span v-show="role_kind !== '2'">
                            开始<a style="color:#1989fa" @click="pragmaticUploadForm('0', '0', '1')"> 手工录入 </a>
                        </span>
                    </p>
                </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import {
  userBasicInfo,
  dataList,
  removecompany,
  yearList,
  fs_dataList,
  fs_delete,
  annotation_delete,
  parseupload, //解析
  saveupload, //上传
  statement_exist,
  change_log, //获取修改时间列表
  fs_management, //判断是否有上期报表
  update_check, //修改查询
  read_annotation_auth,
  annotation_exist,
  change_logOt,
  recognize,
  intellect_rcg,
  coverage,
  tjINdustryList,
} from "@/api";
import { ocrUp } from "@/api/dataManager";
import head from "@/components/head/head";
import * as sesstion from "../../store/localStorage";
import { mapGetters } from "vuex";
export default {
  name: "reportManagement",
  components: {
    vhead: head,
  },
  watch: {
    BType() {
      this.dateTime = "";
    },
  },
  data() {
    return {
      load: false,
      loadText: "确 定",
      user_company: "",
      company_name: "", //客户名
      company_code: "", //客户号
      role_kind: "",
      onUpload: false,
      showFlag: false,
      Flag: false,
      color: {
        color: "#ccc",
      },
      error: "",
      showClick: "s1",
      tableData: [],
      Page: 1, //当前页
      Size: 10, //每页行数
      totals: 0, //列表总行数

      // 附注表
      pragmaticData: [],
      currentPage: 1, //当前页
      pageSize: 10, //每页行数
      total: 0, //列表总行数
      length: 1,

      file: "",
      filename: "",
      filelist: [],
      btn: {
        disable: false,
        message: "确 定",
      },
      // 上一次点击时间
      lastTime: null,
      // 当前时间
      currentTime: null,
      // 超时时间, 如果10分都没有点击页面就算超时
      sys_timeout: 14 * 60 * 1000,
      // 检查的时间，每隔1分钟检查一次有没有超时
      check_time: 1 * 60 * 1000,
      // 计时器
      whole_timer: null,
      luFrom: false,
      codetype: "01",
      dateTime: "",
      BType: "00",
      tnd: "0",
      options: [
        {
          value: "00",
          label: "合并报表",
        },
        {
          value: "01",
          label: "本部报表",
        },
      ],

      pageTypeList: [
        {
          key: "00",
          value: "年报",
        },
        {
          key: "01",
          value: "月报",
        },
        // {
        //   key: "01",
        //   value: "三季报",
        // },
        // {
        //   key: "02",
        //   value: "半年报",
        // },
        // {
        //   key: "03",
        //   value: "一季报",
        // },
        // {
        //     key: "001",
        //     value: "一月报",
        // },
        // {
        //     key: "002",
        //     value: "二月报",
        // },
        // {
        //     key: "003",
        //     value: "三月报",
        // },
        // {
        //     key: "004",
        //     value: "四月报",
        // },
        // {
        //     key: "005",
        //     value: "五月报",
        // },
        // {
        //     key: "006",
        //     value: "六月报",
        // },
        // {
        //     key: "007",
        //     value: "七月报",
        // },
        // {
        //     key: "008",
        //     value: "八月报",
        // },
        // {
        //     key: "009",
        //     value: "九月报",
        // },
        // {
        //     key: "010",
        //     value: "十月报",
        // },
        // {
        //     key: "011",
        //     value: "十一月报",
        // },
        // {
        //     key: "012",
        //     value: "十二月报",
        // },
      ],
      setDateRange: {
        disabledDate: (time) => {
          // 禁用今天之后的日期【当前天可选】
          return time.getTime() > Date.now() + 86400000 - 5;
        },
      },
      dataObj: {},

      sortby: "desc", //基础表排序
      report: "", // 基础表筛选
      sortupdata: "",

      sort_by: "desc", //基础表排序
      report_by: "", // 基础表筛选
      sortupdata_time: "",
      // OCR上传
      upDataFrom: false,

      fbFileList: [],
      fpFileList: [],
      fcFileList: [],
      dataList: [], // 三表汇总
    };
  },

  mounted() {
    // 获取页面中携带的临时token
    // this.getUrlParams()
    this.getDataList();
    // this.get_fs_dataList()
  },
  created() {
    // 启动这个定时器
    // this.isLoginOut();
  },
  computed: {
    ...mapGetters(["name"]),
  },
  methods: {
    handleCommand(item) {
      if (item == "UP") {
        this.onUpload = true;
      } else {
        // this.upDataFrom = true;

        let company_code = sesstion.getLocalData("company").company_code;
        ocrUp({ company_code: company_code }).then(({ data }) => {
          window.open(data.jump_url);
        });
      }
    },

    fbUploada(file, fileList) {
      // let name = file.name;
      //   const isLt2M = file.size / 1024 / 1024 < 5;
      if (!/\.(img|image|PNG|png|jpg|JPG|jpeg)$/.test(file.name)) {
        this.$message.error({
          title: "错误",
          message: "上传文件只能为图片格式",
        });
        this.fbFileList = [];
        return false;
      } else {
        if (this.dateTime == "") {
          this.fbFileList = [];
          return this.$message.error("请输入报表日期！");
        } else {
          this.fbFileList.push({
            name: file.name,
          });
          this.upload(file.raw, "fb"); //fc现fp利润
        }

        // this.filename = file.name;
      }
      //   if (!isLt2M) {
      //     this.$message({
      //       message: "上传文件大小不能超过 5MB!",
      //       type: "error",
      //     });
      //     // this.file = "";
      //     return false;
      //   } else {
      //     console.log(file.raw);
      //     // this.file = file.raw
      //     // this.filename = file.name;
      //   }
    },

    fbhandleExceed(files, fileList) {
      this.$message.error({
        title: "错误",
        message: "只能上传一个文件",
      });
    },

    fbRemove(file, fileList) {
      //   console.log(file, fileList);
      this.fbFileList = [];
      delete this.dataList[0];
    },

    fpUploada(file, fileList) {
      if (!/\.(img|image|PNG|png|jpg|JPG|jpeg)$/.test(file.name)) {
        this.$message.error({
          title: "错误",
          message: "上传文件只能为图片格式",
        });
        this.fpFileList = [];
        return false;
      } else {
        if (this.dateTime == "") {
          this.fpFileList = [];
          return this.$message.error("请输入报表日期！");
        } else {
          this.fpFileList.push({
            name: file.name,
          });
          this.upload(file.raw, "fp"); //fc现fp利润
        }
      }
    },

    fphandleExceed(files, fileList) {
      this.$message.error({
        title: "错误",
        message: "只能上传一个文件",
      });
    },

    fpRemove(file, fileList) {
      //   console.log(file, fileList);
      this.fpFileList = [];
      delete this.dataList[1];
    },

    fcUploada(file, fileList) {
      // let name = file.name;
      //   const isLt2M = file.size / 1024 / 1024 < 5;
      if (!/\.(img|image|PNG|png|jpg|JPG|jpeg)$/.test(file.name)) {
        this.$message.error({
          title: "错误",
          message: "上传文件只能为图片格式",
        });
        this.fcFileList = [];
        return false;
      } else {
        if (this.dateTime == "") {
          this.fcFileList = [];
          return this.$message.error("请输入报表日期！");
        } else {
          this.fcFileList.push({
            name: file.name,
          });
          this.upload(file.raw, "fc"); //fc现fp利润
        }

        // this.filename = file.name;
      }
      //   if (!isLt2M) {
      //     this.$message({
      //       message: "上传文件大小不能超过 5MB!",
      //       type: "error",
      //     });
      //     // this.file = "";
      //     return false;
      //   } else {
      //     console.log(file.raw);
      //     // this.file = file.raw
      //     // this.filename = file.name;
      //   }
    },

    fchandleExceed(files, fileList) {
      this.$message.error({
        title: "错误",
        message: "只能上传一个文件",
      });
    },

    fcRemove(file, fileList) {
      //   console.log(file, fileList);
      this.fcFileList = [];
      delete this.dataList[2];
    },

    // 上传接口
    upload(file, type) {
      this.load = true;
      this.loadText = "上传中";
      let report_time = "";
      if (this.BType == "01") {
        report_time = "0" + this.dateTime.substring(5, 7);
      } else {
        report_time = "00";
      }
      let dataObj = new FormData();
      dataObj.append("company_code", this.company_code);
      dataObj.append("report_year", this.dateTime.substring(0, 4));
      dataObj.append("report_time", report_time);
      dataObj.append("report_type", this.codetype);
      dataObj.append("fcontent", file);
      dataObj.append("statement_kind", type);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      recognize(dataObj, config).then((res) => {
        if (res.data.data.tips !== undefined) {
          this.$confirm(res.data.data.tips, "提示", {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning",
          })
            .then(() => {
              if (type == "fb") {
                this.dataList[0] = res.data.data.result.this.content;
                this.coverage(dataObj, res.data.data.result.this.content);
              }
              if (type == "fp") {
                this.dataList[1] = res.data.data.result.this.content;
                this.coverage(dataObj, res.data.data.result.this.content);
              }
              if (type == "fc") {
                this.dataList[2] = res.data.data.result.this.content;
                this.coverage(dataObj, res.data.data.result.this.content);
              }
              this.load = false;
              this.loadText = "确 定";
            })
            .catch(() => {
              if (type == "fb") {
                this.dataList[0] = res.data.data.result.last.content;
              }
              if (type == "fp") {
                this.dataList[1] = res.data.data.result.last.content;
              }
              if (type == "fc") {
                this.dataList[2] = res.data.data.result.last.content;
              }
              this.load = false;
              this.loadText = "确 定";
            });
        } else {
          if (type == "fb") {
            this.dataList[0] = res.data.data;
          }
          if (type == "fp") {
            this.dataList[1] = res.data.data;
          }
          if (type == "fc") {
            this.dataList[2] = res.data.data;
          }
          this.load = false;
          this.loadText = "确 定";
        }
      });
    },
    coverage(datas, obj) {
      let data = JSON.stringify(obj);
      datas.append("content", data);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      coverage(datas, config).then(({ data }) => {
        if (data.code == 200) {
          this.$message.success("覆盖成功！");
        }
      });
    },
    // OCR取消
    handleCloseOcr() {
      this.load = false;
      this.upDataFrom = false;
      this.dateTime = "";
      this.fbFileList = [];
      this.fpFileList = [];
      this.fcFileList = [];
      this.dataList = [];
    },
    ocrConfirm() {
      // console.log(this.dataList);
      // 参数设置
      let report_time = "";
      if (this.BType == "01") {
        report_time = "0" + this.dateTime.substring(5, 7);
      } else {
        report_time = "00";
      }
      let param = {
        company_code: this.company_code,
        report_time: report_time,
        report_year: this.dateTime.substring(0, 4),
        report_type: this.codetype,
        content: this.dataList,
      };

      if (this.fbFileList.length == 0) {
        return this.$message.error("请上传资产负债表");
      } else if (this.fpFileList.length == 0) {
        return this.$message.error("请上传利润表");
      } else {
        // 接口调用
        intellect_rcg(param).then(({ data }) => {
          // 路由跳转
          sesstion.setLocalData("OCRList", data.data);
          setTimeout(() => {
            this.$router.push("/database/OCR");
          }, 500);
        });
      }
    },

    getUrlParams() {
      this.$loadingShow();
      let url = window.location.href;
      // 通过 ? 分割获取后面的参数字符串
      let urlStr = url.split("?")[1];
      if (urlStr == undefined) {
        this.getDataList();
        this.get_fs_dataList();
      } else {
        // 创建空对象存储参数
        let obj = {};
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split("&");
        for (let i = 0, len = paramsArr.length; i < len; i++) {
          // 再通过 = 将每一个参数分割为 key:value 的形式
          let arr = paramsArr[i].split("=");
          obj[arr[0]] = arr[1];
        }
        this.getToken(obj.t);
      }
    },
    getToken(L_token) {
      this.$http
        .get(window.BASE_URL + "/uc/no_login/company/?t=" + L_token)
        .then((res) => {
          let url = window.location.href;
          // 通过 ? 分割获取后面的参数字符串
          let urlStr = url.split("?")[0];
          window.history.replaceState(null, null, urlStr);
          var userData = {
            auth_token: res.data.token,
          };
          this.$store.dispatch("addLoginUser", userData);
          // 企业信息存入本地
          sesstion.setLocalData("company", res.data.company);
          setTimeout(() => {
            this.getUserInfo(userData.auth_token);
            sessionStorage.token = userData.auth_token;
          }, 500);
        });
    },
    getUserInfo(access) {
      userBasicInfo().then(({ data }) => {
        var userData = {
          ...data,
          auth_token: access,
        };
        this.$store.dispatch("addLoginUser", userData);
        setTimeout(() => {
          // 全部成功后获取列表
          this.getDataList();
          this.get_fs_dataList();
        });
      });
    },

    // 判断是否超时
    isTimeOut() {
      // 页面上一次的点击时间
      this.lastTime = this.$store.state.login.lastTime;
      this.currentTime = new Date().getTime();
      // 超时了
      if (this.currentTime - this.lastTime > this.sys_timeout) {
        return true;
      } else {
        return false;
      }
    },
    isLoginOut() {
      // 每次用定时器之前先清除一下
      clearInterval(this.whole_timer);
      // 这里要备份一下this指针
      let _this = this;
      this.whole_timer = setInterval(function () {
        // 判断一下是否超时，如果超时了就退出
        if (_this.isTimeOut()) {
          window.localStorage.clear();
          window.sessionStorage.clear();
          _this.$message.error("登陆已超时，请重新登录");
          _this.$router.push(`/login`);
          // 如果退出了就清除这个定时器，这里要延迟一秒清除，因为跳转到登录界面会有一点点延迟~
          setTimeout(function () {
            clearInterval(_this.whole_timer);
          }, 1000);
        }
      }, _this.check_time);
    },

    // 表格筛选
    clearFilter() {
      this.$refs.filterTable.clearFilter();
      this.report = "";
      this.getDataList();
    },
    filterChange(value) {
      if (value.report_type.length == 1) {
        this.report = value.report_type[0];
      } else {
        this.report = "";
      }
      this.getDataList();
    },

    sortChange(value) {
      if (value.prop == "creation_time" && value.order == "descending") {
        this.sortby = "desc";
        this.sortupdata = "";
      }
      if (value.prop == "creation_time" && value.order == "ascending") {
        this.sortby = "asc";
        this.sortupdata = "";
      }
      if (value.prop == "update_time" && value.order == "descending") {
        this.sortby = "";
        this.sortupdata = "desc";
      }
      if (value.prop == "update_time" && value.order == "ascending") {
        this.sortby = "";
        this.sortupdata = "asc";
      }
      this.getDataList();
    },
    // 请求数据列表
    getDataList() {
      this.$loadingHide();
      this.user_company = sesstion.getLocalData("company");
      this.company_name = sesstion.getLocalData("company").name; //客户名
      this.company_code = sesstion.getLocalData("company").company_code; //客户号
      this.role_kind = sesstion.getLocalData("userData").role_kind;
      dataList(this.Size, this.Page, {
        company_code: this.company_code,
        creation_time: this.sortby,
        report_type: this.report,
        update_time: this.sortupdata,
      }).then((res) => {
        this.$loadingHide();
        this.tableData = res.data.statementList;
        this.totals = res.data.total;
        this.showFlag = res.data.all_flag;
      });
    },
    //每页条数切换
    SizeChange(pageSize) {
      this.Size = pageSize;
      this.getDataList();
    },
    //页码切换
    CurrentChange(Page) {
      this.Page = Page;
      this.getDataList();
    },
    //关闭弹窗 移除数据
    handleDialogClose() {
      this.filelist = [];
      this.file = "";
      this.onUpload = false;
      this.getDataList();
    },

    handleExceed(e) {
      // 判断是否只能上传一个文件，超过提示报错
      this.$message.error({
        title: "错误",
        message: "只能上传一个文件",
      });
    },
    handleChansge(file, fileList) {
      let name = file.name;
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message.error({
          title: "错误",
          message: "上传文件只能为excel文件，且为xlsx,xls格式",
        });
        this.filelist = [];
        this.file = "";
        return false;
      }
      this.file = file.raw;
      this.filename = file.name;
    },

    clickconfirm() {
      let that = this;
      if (this.file == "") {
        that.$notify.error({
          title: "错误",
          message: "上传文件不能为空",
        });
        return false;
      } else {
        // 文件形式的需要用 formData形式
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("company_code", this.company_code);
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        parseupload(formData, config).then((res) => {
          if (res.data.Status == 0) {
            this.$alert(res.data.Message, "上传失败", {
              confirmButtonText: "确 定",
              type: "error",
            });
          } else {
            if (res.data.exist == 0) {
              saveupload(res.data).then((res) => {
                if (res.data.Status == 1) {
                  this.$alert(res.data.Message, "上传成功", {
                    confirmButtonText: "确 定",
                    type: "success",
                  });
                  this.onUpload = false;
                  that.filelist = [];
                  this.getDataList();
                } else {
                  this.$alert(res.data.Message, "上传失败", {
                    confirmButtonText: "确 定",
                    type: "error",
                  });
                  this.onUpload = false;
                  that.filelist = [];
                }
              });
            } else {
              const h = this.$createElement;
              this.$msgbox({
                title: "温馨提示",
                message: h("div", null, [
                  h(
                    "div",
                    { style: "width:100%;color: #333;text-align: center;" },
                    res.data.Message
                  ),
                  // h('div', { style: 'width:100%;color: #999;text-align: center;' }, '注：当确认提交数据，将扣减一次修改次数')
                ]),
                // type: 'warning',
                showCancelButton: true,
                confirmButtonText: "确 定",
                cancelButtonText: "取 消",
              })
                .then((action) => {
                  saveupload(res.data).then((res) => {
                    if (res.data.Status == 1) {
                      this.$alert(res.data.Message, "上传成功", {
                        confirmButtonText: "确 定",
                        type: "success",
                      });
                      this.onUpload = false;
                      that.filelist = [];
                      this.getDataList();
                    } else {
                      this.$alert(res.data.Message, "上传失败", {
                        confirmButtonText: "确 定",
                        type: "error",
                      });
                      this.onUpload = false;
                      that.filelist = [];
                    }
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消上传",
                  });
                });
            }
          }
        });
      }
    },

    download(value) {
      this.load = true;
      let a = document.createElement("a");
      a.href = `/static/excel/0/${value}`;
      a.download = value;
      a.click();
      setTimeout(() => {
        this.load = false;
      }, 3000);
      // let b = document.createElement('a');
      // b.href = `/static/excel/0/一般企业-合并模板.xlsx`;
      // b.download = '一般企业-合并模板.xlsx';
      // b.click();
      // let c = document.createElement('a');
      // c.href = `/static/excel/0/一般企业-三年一期模板-单体模板.xlsx`;
      // c.download = '一般企业-三年一期模板-单体模板.xlsx';
      // c.click();
      // let d = document.createElement('a');
      // d.href = `/static/excel/0/一般企业-三年一期模板-合并模板.xlsx`;
      // d.download = '一般企业-三年一期模板-合并模板.xlsx';
      // d.click();
      // setTimeout(() => {
      //     this.$loadingHide();
      // }, 2000);
    },

    // 报表分析跳转
    onCompanyLook(item) {
      let param = {
        report_year: item.report_year,
        report_type: item.report_type,
        report_time: item.report_time,
        company_code: this.company_code,
      };
      sesstion.setLocalData("rowList", param);
      yearList(param).then(({ data }) => {
        // sesstion.setLocalData("approval", data.approval)
        if (data.Status == 0) {
          this.$message.error(data.Message);
        } else if (data.Status == 1) {
          this.$confirm(data.Message, "提示", {
            confirmButtonText: "确 定",
            cancelButtonText: "取 消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.back_name = this.$route.name;
              this.$router.push({
                name: "CompanyDetail",
                query: { name: item.name },
              });
            })
            .catch(() => {});
        } else {
          let company = sesstion.getLocalData("company");
          tjINdustryList({
            statistics_code: company.statistics_code || "",
            industry_code: company.SWAI_code || "",
            CSRC_code: company.CSRC_code || "",
          })
            .then((res) => {
              console.info("tjINdustryList", res);
              sesstion.setLocalData("industryData", res.data);
              this.$VM.PublicSearch.$refs.selectTreegb.industryData = res.data;
              this.$VM.PublicSearch.$refs.selectTreegb.setGbDefualtTitle();
            })
            .catch((err) => {
              console.error(err);
            });

          let data = {
            year: item.report_year,
            report_year: item.report_year,
            report_type: item.report_type,
            report_time: item.report_time,
            company_code: company.company_code,
            industry_name: company.tj_category.industry_name,
            stock_code: company.S_or_B_code || "",
          };

          sesstion.setLocalData("companyData", data);
          // sessionStorage.back_name = this.$route.name;
          this.$router.push({
            name: "CompanyDetail",
            query: { name: item.name },
          });
        }
      });
    },

    // 跳转手工
    handMadeFrom(type, value, item) {
      this.tnd = item;
      if (type == 0) {
        this.luFrom = true;
      }
      if (type == 1) {
        sesstion.setLocalData("rowData", value);
        this.$router.push({
          path: "/handMadeFrom",
          query: { name: this.company_name, type },
        });
      }
      if (type == 2) {
        let data = {
          company_code: this.company_code,
          report_year: value.report_year,
          report_time: value.report_time,
          report_type: value.report_type,
        };
        update_check(data).then((res) => {
          if (res.data.Status == 0) {
            this.$alert(res.data.Message, "温馨提示", {
              confirmButtonText: "确 定",
              type: "error",
              showClose: false,
            });
          } else {
            sesstion.setLocalData("rowData", value);
            this.$router.push({
              path: "/handMadeFrom",
              query: { name: this.company_name, type },
            });
          }
        });
      }
      if (type == 3) {
        let data = {
          company_code: this.company_code,
          report_year: value.report_year,
          report_time: value.report_time,
          report_type: value.report_type,
        };
        change_log(data).then((res) => {
          if (res.data.is_changed) {
            sesstion.setLocalData("TimeList", res.data);
            this.$router.push({
              path: "/handMadeFrom",
              query: { name: this.company_name, type },
            });
          } else {
            return this.$notify({
              title: "提示",
              message: "当前报表暂无相关修改记录",
              type: "warning",
            });
          }
        });
      }
    },

    // x下一步
    tick() {
      if (this.dateTime == "") {
        return this.$message.error("请填写报表日期");
      }
      if (this.tnd == 0) {
        var param = {};
        if (this.BType == "01") {
          let report_time = "0" + this.dateTime.substring(5, 7);
          param = {
            company_code: this.company_code,
            report_type: this.codetype,
            report_time: report_time,
            report_year: this.dateTime.substring(0, 4),
          };
        } else {
          param = {
            company_code: this.company_code,
            report_type: this.codetype,
            report_time: this.BType,
            report_year: this.dateTime,
          };
        }
        statement_exist(param).then((res) => {
          if (res.data.Status == 0) {
            this.luFrom = false;
            this.$alert(res.data.Message, "温馨提示", {
              confirmButtonText: "确 定",
              type: "error",
              showClose: false,
            });
            // return this.$message.error(res.data.Message)
          } else {
            fs_management(param).then((res) => {
              sesstion.setLocalData("Data", res.data);
              setTimeout(() => {
                this.$router.push({
                  path: "/handMadeFrom",
                  query: {
                    name: this.company_name,
                    type: 0,
                    code: this.company_code,
                    report_type: this.codetype,
                    time: param.report_time,
                    year: param.report_year,
                  },
                });
              }, 500);
            });
          }
        });
      } else {
        var param = {};
        if (this.BType == "01") {
          let report_time = "0" + this.dateTime.substring(5, 7);
          param = {
            company_code: this.company_code,
            report_type: this.codetype,
            report_time: report_time,
            report_year: this.dateTime.substring(0, 4),
            annotation_type: this.showClick,
          };
        } else {
          param = {
            company_code: this.company_code,
            report_type: this.codetype,
            report_time: this.BType,
            report_year: this.dateTime,
            annotation_type: this.showClick,
          };
        }

        annotation_exist(param).then((res) => {
          if (res.data.Status == 0) {
            this.luFrom = false;
            this.$alert(res.data.Message, "温馨提示", {
              confirmButtonText: "确 定",
              type: "error",
              showClose: false,
            });
            // return this.$message.error(res.data.Message)
          } else {
            var params = {
              flag: 0,
              annotation_type: this.showClick,
              company_code: this.company_code,
              report_type: this.codetype,
              report_time: param.report_time,
              report_year: param.report_year,
            };
            sesstion.setLocalData("routerFlag", params);
            this.$router.push({
              path: "/pragmatic",
              query: { flag: 0 },
            });
          }
        });
      }
    },

    // 删除
    // onDeleteclick(item){
    //     console.log(item);
    //     this.$confirm('删除此表将计算一次修改次数，请确定是否需要删除？', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //     }).then(() => {
    //         let param={
    //             fs_no:item.fs_no,
    //             company_code: this.company_code,
    //             report_year: item.report_year,
    //             report_time: item.report_time,
    //             report_type: item.report_type,
    //         }
    //     fs_delete(param).then((res)=>{
    //         if(res.data.status==1){
    //             this.$message.success(res.data.Message);
    //         }else{
    //             this.$message.error(res.data.Message);
    //         }
    //         this.getDataList()
    //     })
    //     }).catch(() => {
    //     this.$message({
    //         type: 'info',
    //         message: '已取消删除'
    //     });
    //     });

    // },

    jumpPage(item) {
      sesstion.setLocalData("company", item);
      if (item.company_type == 1)
        return this.$router.push({
          name: "smallCompany",
          query: { name: item.name },
        });
      if (item.company_type >= 3)
        return this.$router.push({
          // name: "causeCompany",
          name: "CompanyDetail",
          query: { name: item.name },
        });

      this.$router.push({ name: "CompanyDetail", query: { name: item.name } });
    },

    // 附表按钮
    kindClick(value) {
      this.showClick = value;
      // 调用相应表
      this.get_fs_dataList();
    },

    filterFie(value) {
      if (value.report_type.length == 1) {
        this.report_by = value.report_type[0];
      } else {
        this.report_by = "";
      }
      this.get_fs_dataList();
    },

    sort_Change(value) {
      if (value.prop == "creation_time" && value.order == "descending") {
        this.sort_by = "desc";
        this.sortupdata_time = "";
      }
      if (value.prop == "creation_time" && value.order == "ascending") {
        this.sort_by = "asc";
        this.sortupdata_time = "";
      }
      if (value.prop == "update_time" && value.order == "descending") {
        this.sort_by = "";
        this.sortupdata_time = "desc";
      }
      if (value.prop == "update_time" && value.order == "ascending") {
        this.sort_by = "";
        this.sortupdata_time = "asc";
      }
      this.get_fs_dataList();
    },
    // 获取辅助表
    get_fs_dataList() {
      this.user_company = sesstion.getLocalData("company");
      this.company_name = sesstion.getLocalData("company").name; //客户名
      this.company_code = sesstion.getLocalData("company").company_code; //客户号
      this.role_kind = sesstion.getLocalData("userData").role_kind;
      let param = {
        annotation_type: this.showClick,
        company_code: this.company_code,
        creation_time: this.sort_by,
        report_type: this.report_by,
        update_time: this.sortupdata_time,
      };
      fs_dataList(this.pageSize, this.currentPage, param).then((res) => {
        this.pragmaticData = res.data.statementList;
        this.total = res.data.total;
        this.Flag = res.data.all_flag;
      });
    },

    //附表分页
    //每页条数切换
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.get_fs_dataList();
    },
    //页码切换
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.get_fs_dataList();
    },

    // 附表跳转手工，查看修改
    pragmaticUploadForm(flag, row, item) {
      this.tnd = item;
      if (flag == "0") {
        this.luFrom = true;
      }
      if (flag == 1) {
        var param = {
          flag: flag,
          fsn_no: row.fsn_no,
          annotation_type: this.showClick,
          company_code: this.company_code,
          report_year: row.report_year,
          report_time: row.report_time,
          report_type: row.report_type,
        };
        sesstion.setLocalData("routerFlag", param);
        this.$router.push({
          path: "/pragmatic",
          query: { flag },
        });
      }
      if (flag == 2) {
        let data = {
          annotation_type: this.showClick,
          company_code: this.company_code,
          report_year: row.report_year,
          report_time: row.report_time,
          report_type: row.report_type,
        };
        read_annotation_auth(data).then((res) => {
          if (res.data.Status == 0) {
            this.$alert(res.data.Message, "温馨提示", {
              confirmButtonText: "确 定",
              type: "error",
              showClose: false,
            });
          } else {
            var param = {
              flag: flag,
              fsn_no: row.fsn_no,
              annotation_type: this.showClick,
              company_code: this.company_code,
              report_year: row.report_year,
              report_time: row.report_time,
              report_type: row.report_type,
            };
            sesstion.setLocalData("routerFlag", param);
            this.$router.push({
              path: "/pragmatic",
              query: { flag },
            });
          }
        });
      }
      if (flag == 3) {
        var param = {
          flag: flag,
          fsn_no: row.fsn_no,
          annotation_type: this.showClick,
          company_code: this.company_code,
          report_year: row.report_year,
          report_time: row.report_time,
          report_type: row.report_type,
        };
        sesstion.setLocalData("routerFlag", param);
        let data = {
          company_code: this.company_code,
          report_year: row.report_year,
          report_time: row.report_time,
          report_type: row.report_type,
          annotation_type: this.showClick,
        };
        change_logOt(data).then((res) => {
          if (res.data.is_changed) {
            sesstion.setLocalData("F_data", res.data);
            this.$router.push({
              path: "/pragmatic",
              query: { flag },
            });
          } else {
            return this.$notify({
              title: "提示",
              message: "当前报表暂无相关修改记录",
              type: "warning",
            });
          }
        });
      }
    },

    // 附表删除
    // onDelete(value) {
    //     this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //     }).then(() => {

    //         let param={
    //         fsn_no:value.fsn_no,
    //         annotation_type: this.showClick
    //     }
    //     annotation_delete(param).then((res)=>{
    //         if(res.status==200){
    //             this.$message.success(res.data.Message);
    //         }else{
    //             this.$message.error(res.data.Message);
    //         }
    //             this.get_fs_dataList()
    //     })
    //     }).catch(() => {
    //     this.$message({
    //         type: 'info',
    //         message: '已取消删除'
    //     });
    //     });
    // }
  },
};
</script>

<style lang="less" scoped>
#reportManagement {
  margin-top: 60px;
  padding: 30px 60px 60px 60px;
  background: #ebf2f8;
  .zc {
    display: flex;
    align-items: center;
  }
  /deep/.el-upload-dragger {
    width: 110% !important;
  }

  /deep/.el-message-box__btns button:nth-child(1) {
    width: 200px !important;
  }

  // height: 100vh;
  .active {
    background: #1270ff;
    border: 1px solid #1270ff !important;
    opacity: 1;
    // border-radius: 4px 0px 0px 4px;
    color: #fff;
  }

  .ManageTop,
  .ManageBottom {
    background: #fff;
    border-radius: 4px;
    padding: 25px;
  }

  .ManageTop {
    .title {
      font-size: 18px;
      font-weight: 550;
    }

    .TitleText {
      font-size: 16px;
      color: #999;
    }

    .grid-content {
      color: #333;
      font-size: 16px;
    }
  }

  .ManageBottom {
    min-height: 75vh;

    .too {
      width: 240px;
      padding: 5px;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 12px;
      position: absolute;
      top: -50px;
      z-index: 9;
      border-radius: 3px;
      visibility: visible;
    }

    .btn {
      width: 120px;
      height: 35px;
      font-size: 14px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border: 1px solid #ccc;
    }

    .manage {
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(240, 237, 237);
      padding-bottom: 15px;
    }

    .title {
      font-size: 18px;
      font-weight: 550;
    }

    .see_report {
      cursor: pointer;
      font-size: 14px;
      color: #1172fe;
      // padding: 0 10px;
    }
  }

  .dialogBox {
    // border-top: 1px solid #ccc;
    padding: 0 20px;
  }

  .sc {
    color: #d2d2d3;
  }

  /deep/ .el-dialog__footer {
    text-align: center;
  }
}
</style>
