<template>
  <div id="head" v-if="$route.name != 'downWord'">
    <nav class="clearfix">
      <div class="float-left row align-items-center">
        <img height="38" :src="head_logo" alt />
        <el-menu
          v-if="this.$route.path !== '/Rou'"
          mode="horizontal"
          :default-active="this.$route.path"
          class="pl-3"
          background-color="#00000000"
          text-color="#fff"
          active-text-color="#fff"
          :router="true"
        >
          <el-menu-item index="/ListDebt">首页</el-menu-item>
          <el-menu-item
            index="/database/data-manager"
            v-if="getUserRole('data_nonpublic')"
          >
            企业管理
          </el-menu-item>
          <el-menu-item
            index="/database/RuleSystem"
            v-if="getUserRole('custom_rule_module')"
          >
            自定义规则
          </el-menu-item>

          <el-submenu index="/database" v-if="getUserRole('medium_macro_data')">
            <template slot="title"> 中宏观数据 </template>
            <!-- <el-menu-item index="/database/industry"
              >行业经济指标数据库</el-menu-item
            >
            <el-menu-item index="/database/evaluate"
              >企业绩效评价行业值</el-menu-item
            > -->
            <el-menu-item index="/database/MacroData">宏观数据</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <div class="float-right row userinfo">
        <div class="ml-4 row align-items-center" v-show="isLogin">
          <el-dropdown>
            <a to="javascript:;" class="position-relative">
              <span
                class="user-common-icon d-inline-block position-relative"
              ></span>
              <span
                class="user-name d-inline-block cursor_pionter text-field color-w overflow-ellipsis"
                id="user-name"
              >
                {{ name }}
                <!-- <nobr v-if="isLogin">&nbsp{{ name }}</nobr>
                <nobr v-else>登录/注册</nobr> -->
              </span>
              <img
                src="../../assets/img/head/bar_icon.png"
                class="align-text-top"
              />
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div
                  class="slot_item"
                  style="font-size: 16px"
                  @click="$router.push(`/CenterIndex/BasicInfro`)"
                >
                  <div class="iocn">
                    <i class="iconfont iconjibenxinxi"></i>
                  </div>
                  <div>基本信息</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="slot_item"
                  style="font-size: 16px"
                  @click="$router.push(`/CenterIndex/FindRecord`)"
                >
                  <div
                    class="iocn"
                    style="
                      width: 16px;
                      transform: translateX(-5px);
                      margin-right: 5px;
                    "
                  >
                    <i class="iconfont iconchaxunjilu"></i>
                  </div>
                  <div>查询记录</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="slot_item"
                  style="font-size: 16px"
                  @click="$router.push(`/CenterIndex/OrderManage`)"
                >
                  <div
                    class="iocn"
                    style="
                      width: 16px;
                      transform: translateX(-5px);
                      margin-right: 5px;
                    "
                  >
                    <i class="iconfont iconbaobiaoguanli"></i>
                  </div>
                  <div>订单管理</div>
                </div>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
                <div class="slot_item" style="font-size: 16px" @click="$router.push(`/BuyService`)">
                  <div class="iocn"><i class="iconfont icon48"></i></div>
                  <div >会员服务</div>
                </div>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <div
                  class="slot_item"
                  style="font-size: 16px"
                  @click="loginOut"
                >
                  <div class="iocn"><i class="iconfont icontuichu2"></i></div>
                  <div>退出登录</div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <div class="ml-4 row align-items-center" v-if="!isLogin">
          <router-link to="/login">登录</router-link>
          <span class="ml-2">/</span>
          <router-link to="/register" class="ml-2">注册</router-link>
        </div> -->
      </div>
    </nav>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
import product_info from "@/store/product_info.js";
export default {
  data() {
    return {
      showLists: false,
      head_logo: product_info.head_logo,
      activeIndex: "1",
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin.hasLogin;
    },
    isVIP() {
      if (this.$store.state.isLogin.loginStore.user_role === 2) {
        // 当客户处于登陆并且是vip客户的情况
        return true;
      }
    },
    name() {
      return this.$store.state.isLogin.loginStore.first_name || "";
    },
  },
  methods: {
    onResearch() {
      // 清除联动效果

      this.$store.commit("setListingParame", {
        name: "report_type",
        value: "",
      });
      this.$store.commit("setListingParame", {
        name: "report_time",
        value: "",
      });
      this.$store.commit("setListingParame", {
        name: "selectedYear",
        value: 0,
      });
      this.$router.push(this.$store.state.backSeachPath || "/ListDebt");
    },
    showListsData() {
      this.showLists = true;
    },
    hideListData() {
      this.showLists = false;
    },
    // 退出
    loginOut() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      this.$router.push({ name: "Login" });
    },

    logout() {
      this.$store.commit("setHasLogin", false);
      this.$router.push({ name: "Login" });

      this.$http({
        url: window.BASE_URL + "/auth/token/destroy",
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      }).then(
        (res) => {},
        (res) => {
          console.error("请求失败！", res);
        }
      );
    },
  },
};
</script>

<style lang="less">
.float-left {
  height: 60px !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal {
  > ul {
    background-color: #fff !important;
    margin-left: -20px;

    > li {
      color: #333333 !important;
      text-align: center;
    }
  }

  > .el-menu-item {
    border-bottom: 4px solid transparent;
    padding: 0 40px;
  }

  > .el-menu-item.is-active {
    border-bottom: 4px solid #1989fa;
  }

  > .el-submenu .el-submenu__title {
    border-bottom: 4px solid transparent;
  }

  > .el-submenu.is-active .el-submenu__title {
    border-bottom: 4px solid #1989fa;
  }
}

.float-left {
  .el-submenu__title:hover {
    background-color: rgba(169, 202, 255, 0.5) !important;
  }

  .el-submenu__title i {
    color: #fff !important;
  }
}

.el-menu--popup {
  min-width: 184px;
}

.slot_item {
  min-width: 100px;
  display: flex;
  padding: 5px 0;
}

.hr_font {
  color: #fff;
  font-size: 20px;
}

.research {
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;

  // margin-right: 10px;
  img {
    width: 20px;
    margin-right: 5px;
  }
}

.vip-head {
  bottom: -1px;
  right: -4px;
}

.user-community-list {
  border: 1px solid #4a81fd;
}

.user-community-list a:hover {
  background-color: #4a81fd;
}

.user-community-list a:hover span {
  color: #fff;
}

.user-pre-icon {
  background: url("../../assets/img/head/user-icon-pre.png") no-repeat center
    center;
}

.user-community-list a:hover .user-pre-icon {
  background: url("../../assets/img/head/user-icon-next.png") no-repeat center
    center;
}

.user-community-list a:hover .signOut-pre-icon {
  background: url("../../assets/img/head/out_next.png") no-repeat center center;
}

.signOut-pre-icon {
  background: url("../../assets/img/head/out-pre.png") no-repeat center center;
}

nav {
  height: 60px !important;
  padding-left: 22px !important;
  padding-right: 53px !important;
}

.userinfo {
  margin-top: 12px !important;
}

.logo_img_head {
  height: 43px;
}

#user-name {
  // width: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

// .el-menu-item:hover {
//   background-color: rgba(0, 0, 0, 0) !important;
// }

// .el-submenu:hover {
//   background-color: rgba(0, 0, 0, 0) !important;
// }

@media (max-width: 460px) {
  .menu_d {
    margin-left: 5px;
  }

  .logo_img_head {
    height: 30px;
    margin-top: 5px;
  }

  .ml-4 {
    margin-left: 0.5rem !important;
  }

  #user-name {
    width: 45px;
  }

  .research {
    font-size: 13px;
  }

  /*.mr-3{*/
  /*  margin-right: 0.3rem !important;*/
  /*}*/
}
</style>
